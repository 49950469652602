import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["id"];

  connect() {
    this.nameFor(this.idTarget.textContent);
  }

  nameFor(id) {
    Rails.ajax({
      url: `/api/audit_names/${id}`,
      type: "get",
      success: data => {
        if (typeof data.name !== "undefined") {
          this.idTarget.textContent = `${data.name}`;
        }
      },
      error: error => {}
    });
  }
}
