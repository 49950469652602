import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["diff"];

  connect() {
    this.diffTarget.innerHTML = this.diffTarget.innerHTML.replace(
      this.regex,
      `<a href="${
        this.origin
      }/audits/$1" class="text-teal-500 hover:text-teal-600" data-controller="ajax">
        <span data-target="ajax.id">$1</span>
      </a>`
    );
  }

  get regex() {
    return /([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/gim;
  }

  get origin() {
    return window.location.origin;
  }
}
