import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cardElement", "cardErrors", "form"];

  connect() {
    var stripe = Stripe(this.data.get("key"));
    var elements = stripe.elements();
    var style = {
      base: {
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#718096"
        }
      },
      invalid: {
        color: "#E53E3E",
        iconColor: "#E53E3E"
      }
    };

    var card = elements.create("card", {
      hidePostalCode: true,
      style: style
    });

    card.mount(this.cardElementTarget);

    let controller = this;
    card.addEventListener("change", function(event) {
      var displayError = controller.cardErrorsTarget;
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });

    var form = controller.formTarget;
    form.addEventListener("submit", function(event) {
      event.preventDefault();

      stripe.createToken(card).then(function(result) {
        if (result.error) {
          var errorElement = controller.cardErrorsTarget;
          errorElement.textContent = result.error.message;
        } else {
          controller.stripeTokenHandler(result.token);
        }
      });
    });
  }

  stripeTokenHandler(token) {
    var form = this.formTarget;
    var hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", "card_token");
    hiddenInput.setAttribute("value", token.id);
    form.appendChild(hiddenInput);

    form.submit();
  }
}
