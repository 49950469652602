import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["diff"];

  connect() {
    this.diffTarget.innerHTML = this.diffTarget.innerHTML.replace(
      this.regex,
      `<a href="$1" class="text-teal-500 hover:text-teal-600" target="_blank">$1</a>`
    );
  }

  get regex() {
    return /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/gim;
  }
}
