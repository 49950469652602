import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "sections", "items" ]

  toggle(event) {
    this.index = this.sectionsTargets.indexOf(event.currentTarget)

    this.itemsTargets.forEach((item, index) => {
      item.classList.toggle("hidden", index != this.index)
    })
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    this.data.set("index", value)
  }
}
