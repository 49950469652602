import { Controller } from "stimulus";
var jstz = require("jstimezonedetect");

export default class extends Controller {
  static targets = ["select"];

  connect() {
    const jsTimezoneMapping = JSON.parse(this.data.get("zones"));
    const tz = jsTimezoneMapping[jstz.determine().name()] || "UTC";

    this.selectTarget.value = tz;
  }
}
